import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {DeviceDetectorService} from 'ngx-device-detector';
import { parse, unparse, ParseConfig } from 'papaparse';


@Component({
  templateUrl: './new-list-tools.component.html'
})
export class NewListToolsComponent implements OnInit {
  private listFileData: string;
  private filterFileData: string;
  public checkButtonDisable = false;

  public listForm = new FormGroup({
    listCsvCharset: new FormControl('CP-1251'),
    filterCsvCharset: new FormControl('CP-1251'),
  });

  constructor(
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit(): void {
    if (this.deviceService.os !== 'windows' && this.deviceService.os !== 'unknown') {
      this.listForm.controls['listCsvCharset'].setValue('UTF-8');
      this.listForm.controls['filterCsvCharset'].setValue('UTF-8');
    }
  }

  listFileChange(event) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      this.listFileData = <string>reader.result;
    };
    reader.readAsText(file);
  }

  filterFileChange(event) {
    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      this.filterFileData = <string>reader.result;
    };
    reader.readAsText(file);
  }

  papaConfig(name: string): ParseConfig {
    return {
      encoding: this.listForm.controls[`${name}CsvCharset`].value
    };
  }

  makeNewList() {
    this.checkButtonDisable = true;
    try {
      // Read CSV
      if (typeof this.listFileData === 'undefined') {
        return alert('Исходный файл не выбран');
      }
      if (typeof this.filterFileData === 'undefined') {
        return alert('Файл-фильтр не выбран');
      }
      const source = parse(this.listFileData, this.papaConfig('list'));
      if (source.data.length === 0) {
        alert(`Ошибки в исходном файле или пустой файл\n${source['errors']}`);
        throw Error('Source CSV error');
      }
      if (source.data.length < 2) {
        alert(`В исходном файле слишком мало строк (${source.data.length})\n${source['errors']}`);
        throw Error('Source CSV too short');
      }
      const header = source.data.shift();
      const filterRes = parse(this.filterFileData, this.papaConfig('list'));
      if (filterRes.data.length === 0) {
        alert(`Ошибки в файле-фильтре или пустой файл\n${source['errors']}`);
        throw Error('Filter CSV error');
      }
      const filter = filterRes.data.map(v => v[0]).filter(v => !!v);
      console.log('source', source);
      console.log('filterRes', filterRes);
      console.log('filter', filter);

      // Filter
      const res = source.data.filter(v => filter.indexOf(v[0]) !== -1);
      res.unshift(header);
      console.log('res', res);

      // Download result
      const resCsv = unparse(res, this.papaConfig('list'));
      const resCsvFile = new Blob([resCsv], {type: 'text/csv'});
      const url = window.URL.createObjectURL(resCsvFile);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = 'new_list.csv';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    } catch (e) {
      alert(`Ошибка: ${e}`);
      throw e;
    } finally {
      this.checkButtonDisable = false;
    }
  }
}
