import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/sender/api/')) {
      req.headers.set('content-type', 'application/json');
    }
    return next.handle(req).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: Response | any) {
    console.log('Http error', error);
    return throwError(error);
  }

}
