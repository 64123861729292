import {Injectable} from '@angular/core';
import {JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {authConfig} from './auth.config';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private oauthService: OAuthService) { }

  public configureAuth(): Promise<boolean> {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    // Load Discovery Document and then try to login the user
    return this.oauthService.loadDiscoveryDocumentAndTryLogin().then(res => {
      this.oauthService.setupAutomaticSilentRefresh();
      return res;
    });
  }

  public get loggedIn() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  public requireLogin() {
    if (!this.loggedIn) {
      this.login();
    }
  }

  public get name() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['nickname'];
  }

  public claims(): object {
    return this.oauthService.getIdentityClaims();
  }

  public logout() {
    this.oauthService.logOut();
  }

  public login() {
    this.oauthService.initImplicitFlow();
  }

}
