import {Pipe, PipeTransform} from '@angular/core';

// Printable statuses
export const targetPrintableStatuses = {
  INITIAL: 'В очереди',
  PROCESS: 'В процессе',
  RETRY: 'Повторная попытка',
  FAIL: 'Ошибка',
  COMPLETE: 'Отправлено'
};

@Pipe({
  name: 'appMailingTargetStatus'
})
export class MailingTargetStatusPipe implements PipeTransform {
  transform(status: any): string {
    return targetPrintableStatuses[status];
  }
}
