/**
 * Auth guard
 */
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {GlobalLoadingService} from './shared/global-loading.service';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private authService: AuthService, private globalLoading: GlobalLoadingService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authService.configureAuth().then(() => {
        if (this.authService.loggedIn) {
          if (localStorage.getItem('__auth_redirect_url')) {
            this.globalLoading.show_name('auth_redirect');
            const url = localStorage.getItem('__auth_redirect_url');
            console.log('get', '__auth_redirect_url', localStorage.getItem('__auth_redirect_url'));
            localStorage.removeItem('__auth_redirect_url');
            observer.next(false);
            const obs = this.router.events.subscribe((routerEvent) => {
              if (routerEvent instanceof NavigationEnd ||
                  routerEvent instanceof NavigationCancel ||
                  routerEvent instanceof NavigationError) {
                this.router.navigateByUrl(url).then(() => {
                });
                obs.unsubscribe();
              }
            });
          } else {
            this.globalLoading.hide_name('auth_redirect');
            observer.next(true);
          }
        } else {
          this.globalLoading.show_name('auth_redirect');
          localStorage.setItem('__auth_redirect_url', location.pathname);
          console.log('set', '__auth_redirect_url', location.pathname);
          this.authService.login();
          observer.next(false);
        }
      }).finally(() => {
        this.globalLoading.hide_name('auth');
      });
    });
  }

}


