/**
 * Sender default component: front page
 */

import {Component} from '@angular/core';

@Component({
  selector: 'app-default',
  template: `
    <h3 class="mb-lg-4">Главная страница</h3>
    <div>
      <p>
        <a routerLink="/mailing/tasks/create">Создать задание</a>
      </p>
      <p>
        <a routerLink="/mailing/tasks/show">Посмотреть список заданий</a>
      </p>
    </div>
  `
})
export class DefaultComponent {

}
