import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MailingTaskService } from './services/mailing-task-service';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import {
  IEmailOptions, IPersonalOptions, IListMethodFileCond,
  ISmsOptions, ListMethod, MailingTask, TaskStatus
} from './models/mailing-task.model';
import { CanDeactivateGuard } from '../shared/can-deactivate-guard.service';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';


@Component({
  templateUrl: './create-mailing-task.component.html',
})
export class CreateMailingTaskComponent implements OnInit, OnDestroy, CanDeactivateGuard {

  private _bypass_navigation = false;

  public today = new Date();

  public taskToCheck?: MailingTask;

  public fileMethodSelected = true;
  public filterMethodSelected = false;

  public _emailWithCc = false;
  public _emailWithBcc = false;

  public csvData: string;

  public checkButtonDisable = false;
  public checkError: string;

  public createButtonDisable = false;
  public createError: string;

  @ViewChild('listFile') listFile: ElementRef;
  public showListFileRefresh = false;

  public taskForm: FormGroup;

  private _validationSubscriptions: Array<Subscription> = [];

  constructor(
    private deviceService: DeviceDetectorService,
    private taskService: MailingTaskService,
    private router: Router
  ) { }

  validatorsIfTrue(mainControl: AbstractControl, validatingControl: AbstractControl, validators: Array<ValidatorFn>) {
    this._validationSubscriptions.push(validatingControl.valueChanges.subscribe(() => {
      mainControl.updateValueAndValidity();
    }));
    validators = validators.map((validator) => {
      return (control: AbstractControl): { [key: string]: any } | null => {
        const formGroup: FormGroup | FormArray = control.parent;
        if (!formGroup) {
          return;
        }
        if (!validatingControl.value) {
          return null;
        }
        console.log('VALIDATING', validatingControl, control);
        return validator(control);
      };
    });

    mainControl.setValidators(validators);
  }

  ngOnInit(): void {
    this.taskForm = new FormGroup({
      taskName: new FormControl('', [
        Validators.required,
        Validators.minLength(5)
      ],
        [
          (control) => {  // Check name existence
            return timer(500).pipe(
              switchMap(() => this.taskService.checkName(control.value)),
              map(val => val ? { exists: control.value } : null)
            );
          }
        ]),
      csvCharset: new FormControl('cp1251'),
      sendSms: new FormControl(false),
      sendEmail: new FormControl(true),
      sendPersonal: new FormControl(false),
      ignoreSubscriptionFlag: new FormControl(false),
      smsText: new FormControl(''),
      emailFrom: new FormControl(`no-reply@${environment.baseDomain}`),
      emailCc: new FormControl(``),
      emailBcc: new FormControl(``),
      emailToMain: new FormControl(true),
      emailToAdd: new FormControl(false),
      emailToBuh: new FormControl(false),
      emailText: new FormControl(''),
      emailSubject: new FormControl(''),
      personalSubject: new FormControl(),
      personalText: new FormControl(''),
      personalDateFrom: new FormControl(new Date()),
      personalDateToEnabled: new FormControl(false),
      personalDateTo: new FormControl(null),
      personalPopup: new FormControl(true),
    }, [
        /* Send methods */
        (form: FormGroup) => {
          if (!form.controls['sendSms'].value && !form.controls['sendEmail'].value
            && !form.controls['sendPersonal'].value) {
            return { noSendMethod: true };
          }
          return null;
        },
        /* Email targets */
        (form: FormGroup) => {
          if (form.controls['sendEmail'].value && !(
            form.controls['emailToMain'].value ||
            form.controls['emailToAdd'].value ||
            form.controls['emailToBuh'].value
          )) {
            return { noEmailTarget: true };
          }
          return null;
        }
      ]);

    const controls = this.taskForm.controls;
    this.validatorsIfTrue(controls.smsText, controls.sendSms, [Validators.required]);
    this.validatorsIfTrue(controls.emailFrom, controls.sendEmail, [Validators.required, Validators.email]);
    this.validatorsIfTrue(controls.emailText, controls.sendEmail, [Validators.required]);
    this.validatorsIfTrue(controls.emailSubject, controls.sendEmail, [Validators.required]);
    this.validatorsIfTrue(controls.personalSubject, controls.sendPersonal, [Validators.required]);
    this.validatorsIfTrue(controls.personalText, controls.sendPersonal, [Validators.required]);

    if (this.deviceService.os !== 'windows' && this.deviceService.os !== 'unknown') {
      this.taskForm.controls['csvCharset'].setValue('UTF-8');
    }
  }

  ngOnDestroy(): void {
    this._validationSubscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private _testBasic(controlName: string, validatorName: string, error: string, errors: Array<string>) {
    if (this.taskForm.controls[controlName].errors) {
      if (validatorName in this.taskForm.controls[controlName].errors) {
        errors.push(error);
      }
    }
  }

  get formErrors(): string {
    const errors: Array<string> = [];
    console.log(this.taskForm);
    if (typeof this.csvData === 'undefined') {
      errors.push('Не загружен CSV файл');
    }
    if (this.taskForm.errors) {
      if ('noSendMethod' in this.taskForm.errors) {
        errors.push('Не выбран ни один метод отправки');
      }
      if ('noEmailTarget' in this.taskForm.errors) {
        errors.push('Не выбран ни один тип email получателя');
      }
    }
    this._testBasic('taskName', 'required', 'Не введено имя задания', errors);
    if (this.taskForm.controls['taskName'].errors) {
      if ('minlength' in this.taskForm.controls['taskName'].errors) {
        const min = this.taskForm.controls['taskName'].errors['minlength'].requiredLength;
        errors.push(`Имя задания должно быть не короче ${min} символов`);
      }
      if ('exists' in this.taskForm.controls['taskName'].errors) {
        const name = this.taskForm.controls['taskName'].errors['exists'];
        errors.push(`Имя задания "${name}" уже существует`);
      }
    }
    this._testBasic('smsText', 'required', 'Не введён текст SMS', errors);
    this._testBasic('emailText', 'required', 'Не введён текст email', errors);
    this._testBasic('emailFrom', 'required', 'Не введён отправитель email', errors);
    this._testBasic('emailFrom', 'email', 'Неверный формат отправителя email', errors);
    this._testBasic('emailSubject', 'required', 'Не введена тема email', errors);
    this._testBasic('personalSubject', 'required', 'Не введена тема личного сообщения', errors);
    this._testBasic('personalText', 'required', 'Не введен текст личного сообщения', errors);
    console.log('form errors', 'form errors', this.taskForm.errors, 'controls', this.taskForm.controls);
    return 'Поправьте ошибки: \n' + errors.join('\n');
  }

  get emailWithCc(): boolean {
    return this._emailWithCc;
  }

  set emailWithCc(val: boolean) {
    this._emailWithCc = val;
    if (!this._emailWithCc) {
      this.taskForm.controls['emailCc'].setValue('');
    }
  }

  get emailWithBcc(): boolean {
    return this._emailWithBcc;
  }

  set emailWithBcc(val: boolean) {
    this._emailWithBcc = val;
    if (!this._emailWithBcc) {
      this.taskForm.controls['emailBcc'].setValue('');
    }
  }

  get bypass_navigation() {
    const res = this._bypass_navigation;
    this._bypass_navigation = false;
    return res;
  }

  set bypass_navigation(val: boolean) {
    this._bypass_navigation = val;
  }


  canDeactivate(): boolean {
    return !this.taskForm.dirty || this.bypass_navigation;
  }

  get listFileObject(): File {
    return this.listFile.nativeElement.files[0];
  }

  listFileChange() {
    const file: File = this.listFileObject;
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      console.log(`Loaded file ${file.name}`);
      this.csvData = <string>reader.result;
    };
    reader.readAsDataURL(file);
  }

  listFileRefresh() {
    this.listFileChange();
    this.showListFileRefresh = true;
    window.setTimeout(() => {
      this.showListFileRefresh = false;
    }, 1000);
  }

  get listFileShortName(): string {
    const parts: Array<string> = this.listFileObject.name.split('/');
    return parts[parts.length - 1];
  }

  checkTask() {
    // Validate form
    this.taskToCheck = undefined;
    console.log('Checking task');
    console.log(this.taskForm);
    if (!this.taskForm.valid) {
      this.checkError = this.formErrors;
      return;
    }
    // Create task object
    this.checkButtonDisable = true;
    this.checkError = '';

    let fileCond: IListMethodFileCond = null;
    if (this.fileMethodSelected) {
      // Create file task object
      fileCond = {
        file_charset: this.taskForm.controls['csvCharset'].value,
        file_content: this.csvData
      };
    } else if (this.filterMethodSelected) {
      throw Error('filter method not implemented');
    } else {
      throw Error('List method is unknown');
    }

    // Create common options
    let sms_options: ISmsOptions = null;
    if (this.taskForm.controls['sendSms'].value) {
      sms_options = {
        template: this.taskForm.controls['smsText'].value,
        ignore_subscription_flag: this.taskForm.controls['ignoreSubscriptionFlag'].value
      };
    }
    let email_options: IEmailOptions = null;
    if (this.taskForm.controls['sendEmail'].value) {
      email_options = {
        template: this.taskForm.controls['emailText'].value,
        subject_template: this.taskForm.controls['emailSubject'].value,
        address_from: this.taskForm.controls['emailFrom'].value,
        address_cc: this.emailWithCc ? this.taskForm.controls['emailCc'].value : null,
        address_bcc: this.emailWithBcc ? this.taskForm.controls['emailBcc'].value : null,
        address_to_main: this.taskForm.controls['emailToMain'].value,
        address_to_add: this.taskForm.controls['emailToAdd'].value,
        address_to_buh: this.taskForm.controls['emailToBuh'].value,
      };
    }

    let personal_options: IPersonalOptions = null;
    if (this.taskForm.controls['sendPersonal'].value) {
      let date_to: Date = null;
      if (this.taskForm.controls['personalDateToEnabled'].value) {
        date_to = this.taskForm.controls['personalDateTo'].value;
      }
      personal_options = {
        template: this.taskForm.controls['personalText'].value,
        subject_template: this.taskForm.controls['personalSubject'].value,
        date_from: this.taskForm.controls['personalDateFrom'].value,
        popup: this.taskForm.controls['personalPopup'].value,
        date_to
      };
    }

    // Create task object
    const mailingTask: MailingTask = {
      name: this.taskForm.controls['taskName'].value,
      list_method: ListMethod.File,
      list_method_file_cond: fileCond,
      sms_options,
      email_options,
      personal_options,
      status: TaskStatus.Stopped,
      mailing_items: []
    };

    console.log('Creating task to check: ', mailingTask);

    this.taskService.checkTask(mailingTask).subscribe(
      (task) => {
        this.checkButtonDisable = false;
        console.log('Setting task to check: ', task);
        this.taskToCheck = task;
      },
      (e) => {
        this.checkButtonDisable = false;
        this.checkError = e.error;
      },
    );

  }

  createTask() {
    // Validate form
    if (!this.taskForm.valid) {
      this.createError = this.formErrors;
      return;
    }
    if (!this.taskToCheck) {
      throw new Error('Task to check must be set while create');
    }
    this.createButtonDisable = true;

    // Prepare task object
    const taskCreate = { ...this.taskToCheck };
    taskCreate.mailing_items = [];
    taskCreate.created_by = null;

    // Create task
    this.taskService.createTask(taskCreate).subscribe(
      (task) => {
        this.createButtonDisable = false;
        // Navigate from task
        this.bypass_navigation = true;
        this.router.navigate(['/mailing/tasks/show', task.id]).then();
      },
      (e) => {
        this.createButtonDisable = false;
        switch (typeof e.error) {
          case 'string': {
            this.createError = e.error;
          }
          case 'undefined': {
            this.createError = e.error;
            break;
          }
          case 'number': {
            this.createError = e.error;
            break;
          }
          default: {
            let parts = [];
            if (Array.isArray(e.error)) {
              parts = e.error;
            } else {
              for (const k in e.error) {
                parts.push(`${k}=${e.error[k]}`);
              }
            }
            this.createError = parts.join(' ')
          }
        }

      }

    );

  }

}
