import {Pipe, PipeTransform} from '@angular/core';

export const taskPrintableStatuses = {
  STOPPED: 'Остановлено',
  STARTED: 'В процессе',
  FINISHED: 'Завершено',
};

@Pipe({
  name: 'appMailingTaskStatus'
})
export class MailingTaskStatusPipe implements PipeTransform {
  transform(status: string): string {
    return taskPrintableStatuses[status];
  }
}
