import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MailingTask} from './models/mailing-task.model';


@Component({
  template: `
    <h3><a routerLink="/mailing/tasks/show">Задания</a> -></h3>
    <app-display-mailing-task [task]="task"></app-display-mailing-task>
  `
})
export class ShowMailingTaskComponent implements OnInit {
  task: MailingTask;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.task = this.route.snapshot.data['task'];
  }
}
