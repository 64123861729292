<h3 class="mb-lg-4">Создать задание</h3>

<form [formGroup]="taskForm">

  <div class="form-group row">
    <label for="taskName" class="col-sm-2 col-form-label">Название задания</label>
    <div class="col-sm-10">
      <input class="form-control" type="text" id="taskName" formControlName="taskName" placeholder="Название" autofocus>
    </div>
  </div>

  <tabset #listMethodTabSet >
    <tab heading="Из файла" [active]="fileMethodSelected">
      <!-- Create with a file -->
      <div class="mt-4">
          <div class="form-group row">
            <label for="csvCharset" class="col-sm-2 col-form-label">Кодировка файла</label>
            <div class="col-sm-10">
              <select class="form-control" id="csvCharset" formControlName="csvCharset">
                <option value="cp1251" selected>CP-1251 (Windows, Excel)</option>
                <option value="UTF-8">UTF-8 (OpenOffice, Google Таблицы, LibreOffice, Linux)</option>
                <option value="UTF-8-SEMI">Excel@Mac (UTF-8, ";")</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="listFile" class="col-sm-2 col-form-label">CSV файл
              <a href="https://docs.google.com/document/d/1EDs1gqIacTs0QFwn4tzRTQDcNCMK5TVdzVKYWUsB39k/edit"
                 target="_sender_doc"
                 class="text-muted"
              >А как?</a>
            </label>
            <div class="col-sm-10">
              <input #listFile [hidden]="csvData" type="file" class="form-control" id="listFile" (change)="listFileChange()"/>
              <span *ngIf="csvData">
                Прикреплен файл <b class="text-secondary" [textContent]="listFileShortName"></b>
                <button class="btn btn-link" (click)="listFile.value = ''; csvData = '';">Изменить</button>
                <button class="btn btn-link" (click)="listFileRefresh()">Обновить</button>
                <span *ngIf="showListFileRefresh" class="text-danger">файл обновлён</span>
              </span>
            </div>
          </div>
      </div>
    </tab>

    <tab heading="Фильтр" [active]="filterMethodSelected">
      <div class="bg-danger mt-4">
        В разработке((
      </div>
    </tab>

  </tabset>

  <hr>

  <div class="mt-4" *ngIf="!listMethodTabSet.tabs[1].active">


    <!-- Message templates -->

    <div class="form-group row">
      <label class="col-sm-2 col-form-label">Метод отправки</label>
      <div class="col-sm-10">
        <input class="form-check-input" type="checkbox" id="sendSms" formControlName="sendSms">
        <label class="form-check-label" for="sendSms">
          SMS
        </label>
        <br>
        <input class="form-check-input" type="checkbox" id="sendEmail" formControlName="sendEmail">
        <label class="form-check-label" for="sendEmail">
          Email
        </label>
        <br>
        <input class="form-check-input" type="checkbox" id="sendPersonal" formControlName="sendPersonal">
        <label class="form-check-label" for="sendPersonal">
          ЛК
        </label>
      </div>
    </div>

    <div class="mt-lg-5" *ngIf="taskForm?.controls['sendSms'].value">
      <!-- SMS options -->
      <hr>
      <div class="mb-2">
        <strong>Опции SMS:</strong>
      </div>
      <div class="form-check mb-1">
        <label class="form-check-label">
          <input class="form-check-input" type="checkbox" formControlName="ignoreSubscriptionFlag">
          Игнорировать запрет отправки СМС
        </label>
      </div>
      <div class="form-group">
        <label for="smsText">Текст SMS сообщения</label>
        <textarea id="smsText" formControlName="smsText"
                  class="form-control" rows="5" placeholder="SMS"
        ></textarea>
      </div>
    </div>

    <div class="mt-lg-5" *ngIf="taskForm?.controls['sendEmail'].value">
      <!-- Email options -->
      <hr>
      <div class="mb-2">
        <strong>Опции Email:</strong>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="emailFrom">E-mail отправитель</label>
        <div class="col-sm-10">
          <input type="email" id="emailFrom" class="form-control" formControlName="emailFrom"
                 placeholder="mail@domain.com">
        </div>
      </div>
      <!-- CC + BCC -->
      <div class="form-group row" *ngIf="emailWithCc">
        <label class="col-sm-2 col-form-label" for="emailСс">
          <button class="btn btn-link" (click)="emailWithCc = false">
            <i class="fa fa-times"></i>
          </button>
          Копия
        </label>
        <div class="col-sm-10">
          <input type="email" id="emailСс" class="form-control" formControlName="emailCc"
                 placeholder="mail@domain.com">
        </div>
      </div>
      <div class="form-group row" *ngIf="emailWithBcc">
        <label class="col-sm-2 col-form-label" for="emailBcc">
          <button class="btn btn-link" (click)="emailWithBcc = false">
            <i class="fa fa-times"></i>
          </button>
          Скрытая копия
        </label>
        <div class="col-sm-10">
          <input type="email" id="emailBcc" class="form-control" formControlName="emailBcc"
                 placeholder="mail@domain.com">
        </div>
      </div>
      <div class="form-group row" *ngIf="!emailWithCc || !emailWithBcc">
        <button class="btn btn-link" (click)="emailWithCc = true" *ngIf="!emailWithCc">
          <i class="fa fa-plus"></i> CC (Копия)
        </button>
        <button class="btn btn-link" (click)="emailWithBcc = true" *ngIf="!emailWithBcc">
          <i class="fa fa-plus"></i> BCC (Скрытая копия)
        </button>
      </div>
      <!-- /CC + BCC -->

      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Тип адреса получателя</label>
        <div class="col-sm-10">
          <input class="form-check-input" type="checkbox" id="emailToMain" formControlName="emailToMain">
          <label class="form-check-label" for="emailToMain">
            Основной email
          </label>
          <br>
          <input class="form-check-input" type="checkbox" id="emailToAdd" formControlName="emailToAdd">
          <label class="form-check-label" for="emailToAdd">
            Дополнительный/технический email
          </label>
          <br>
          <input class="form-check-input" type="checkbox" id="emailToBuh" formControlName="emailToBuh">
          <label class="form-check-label" for="emailToBuh">
            Бухгалтерский email
          </label>
          <br>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="emailSubject">Тема письма</label>
        <div class="col-sm-10">
          <input type="text" id="emailSubject" class="form-control" formControlName="emailSubject"
                 placeholder="Тема">
        </div>
      </div>
      <div class="form-group">
        <label for="emailText">Текст E-mail сообщения</label>
        <app-ngx-editor id="emailText" placeholder="Email" formControlName="emailText"
                        [config]="{height: '200px'}"
        ></app-ngx-editor>
      </div>
    </div>

    <div class="mt-lg-5" *ngIf="taskForm?.controls['sendPersonal'].value">
      <!-- Personal options -->
      <hr>
      <div class="mb-2">
        <strong>Опции сообщения в ЛК:</strong>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="personalSubject">Заголовок</label>
        <div class="col-sm-10">
          <input type="text" id="personalSubject" class="form-control" formControlName="personalSubject"
                 placeholder="Заголовок">
        </div>
      </div>
      <div class="form-group">
        <label for="personalText">Текст сообщения в ЛК</label>
        <textarea id="personalText" formControlName="personalText"
                  class="form-control" rows="5" placeholder="Сообщение в ЛК"
        ></textarea>
      </div>
      <div class="form-check mb-1">
        <label class="form-check-label">
          <input class="form-check-input" type="checkbox" formControlName="personalPopup">
          Показывать попап-окном
        </label>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="personalDateFrom">Дата начала</label>
        <div class="col-sm-10">
          <input type="text" id="personalDateFrom" class="form-control" formControlName="personalDateFrom"
                 placeholder="Дата начала" bsDatepicker placement="top" [minDate]="today">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="personalDateToEnabled">Дата окончания</label>
        <div class="col-sm-10 input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input type="checkbox" aria-label="Включить дату окончания"
                     formControlName="personalDateToEnabled" id="personalDateToEnabled">
            </div>
          </div>
          <input type="text" id="personalDateTo" class="form-control" formControlName="personalDateTo"
                 placeholder="Дата окончания" bsDatepicker placement="top" [minDate]="today"
                 [attr.disabled]="!taskForm?.controls['personalDateToEnabled'].value? '': null">
        </div>
      </div>
    </div>

    <div class="form-group">
      <button (click)="checkTask()" class="btn btn-success float-right" [disabled]="checkButtonDisable">
        <span *ngIf="!taskToCheck">
          Далее - проверка
        </span>
        <span *ngIf="taskToCheck">
          Проверить заново
        </span>
      </button>
      <i class="clearfix"></i>
    </div>

  </div>

</form>
<div *ngIf="checkError">
  <h4 class="text-danger">Ошибка</h4>
  <pre style="white-space: pre-wrap;" class="text-danger" >{{ checkError }}</pre>
</div>

<!-- Check task -->
<ng-container *ngIf="taskToCheck">
  <div class="border p-3 mb-lg-2" appScrollToAfterView>
    <app-display-mailing-task [task]="taskToCheck" [check]="true"></app-display-mailing-task>
  </div>

  <button (click)="createTask()" class="btn btn-success float-right" [disabled]="createButtonDisable">
    Всё хорошо? Создать задание.
  </button>

  <div *ngIf="createError">
    <h4 class="text-danger">Ошибка</h4>
    <pre style="white-space: pre-wrap;" class="text-danger" >{{ createError }}</pre>
  </div>
</ng-container>
