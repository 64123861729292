import {Component} from '@angular/core';


@Component({
  template: `
    <h3>Утилиты</h3>
    <ul>
      <li>
        <a routerLink="/tools/new_list">Создание нового списка на базе старого</a>
      </li>
    </ul>
`
})
export class ToolsListComponent {

}
