import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MailingTask, TaskReportCriteria} from '../models/mailing-task.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MailingTaskService {
  constructor(private http: HttpClient) {}

  private dateToTs(date: Date, floor: boolean): number {
    if (floor) {
      date.setHours(0, 0, 0);
    } else {
      date.setHours(23, 59, 59);
    }
    return Math.ceil(date.getTime() / 1000);
  }

  public checkTask(task: MailingTask): Observable<MailingTask> {
    return this.http.post<MailingTask>('/sender/api/tasks/check', task);
  }

  public createTask(task: MailingTask): Observable<MailingTask> {
    return this.http.post<MailingTask>('/sender/api/tasks', task);
  }

  public getTask(id: number): Observable<MailingTask> {
    return this.http.get<MailingTask>(`/sender/api/tasks/${id}`);
  }

  public getTaskList(): Observable<MailingTask[]> {
    return this.http.get<MailingTask[]>(`/sender/api/tasks`);
  }

  public setTaskStatus(id: number, status: string) {
    return this.http.post<void>(`/sender/api/tasks/status/${id}`, {status: status});
  }

  public checkName(name: string): Observable<boolean> {
    return this.http.get<MailingTask[]>('/sender/api/tasks', {params: {name}}).pipe(map(val => val.length > 0));
  }

  public getTaskReport(criteria: TaskReportCriteria): Observable<{data: string}> {
    let params = new HttpParams();
    if (criteria.id) { params = params.set('id', criteria.id.toString()); }
    if (criteria.created_at__gt) { params = params.set('created_at__gt', this.dateToTs(criteria.created_at__gt, true).toString()); }
    if (criteria.created_at__lt) { params = params.set('created_at__lt', this.dateToTs(criteria.created_at__lt, false).toString()); }
    if (criteria.source) { params = params.set('source', criteria.source); }

    return this.http.get<{data: string}>(`/sender/api/tasks/report`, {params: params});
  }

  public getTaskSources(): Observable<string[]> {
    return this.http.get<string[]>('/sender/api/tasks/sources');
  }

}
