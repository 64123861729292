/**
 * Mailing task resolver
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {MailingTask} from '../models/mailing-task.model';
import {MailingTaskService} from '../services/mailing-task-service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MailingTaskListResolver implements Resolve<Observable<MailingTask[]>> {
  constructor(private service: MailingTaskService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MailingTask[]> {
    return this.service.getTaskList()
      .pipe(catchError((err) => {
        alert(err.message);
        return throwError(err);
      }));
  }

}
