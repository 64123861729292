<app-nav *ngIf="authService.loggedIn" (emitLogin)="login()" (emitLogout)="logout()"></app-nav>

<div class="container mt-lg-4 position-relative">

  <!-- if loading -->
  <div *ngIf="globalLoading.visible" class="loader mask">
    <img src="/assets/loader-spinner-dark-big.gif" alt="Загрузка">
    Загрузка...
  </div>

  <div class="ml-2 mr-2">

    <router-outlet></router-outlet>

    <nav class="mt-xl-5 mb-xl-1">
      <small class="text-muted">Sender</small>
    </nav>
  </div>

</div>
