import { Component } from '@angular/core';
import {AuthService} from './auth.service';
import {NavigationCancel, Event, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {GlobalLoadingService} from './shared/global-loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  constructor(public authService: AuthService, private router: Router, public globalLoading: GlobalLoadingService) {
    this.globalLoading.show_name('auth');

    router.events.subscribe(this.checkRouterEvent.bind(this));
  }

  login() {
    this.authService.login();
    this.globalLoading.show_name('auth_redirect');
  }

  logout() {
    this.authService.logout();
    this.globalLoading.show_name('auth_redirect');
  }

  checkRouterEvent(routerEvent: Event) {
    if (routerEvent instanceof NavigationStart) {
      this.globalLoading.show_name('nav');
    }
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.globalLoading.hide_name('nav');
    }
  }

}
