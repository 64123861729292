<div *ngIf="tasks.length == 0">
  <strong>Нет заданий</strong>
</div>

<pagination-controls
  (pageChange)="pageNum = $event"
  previousLabel=""
  nextLabel=""
></pagination-controls>

<table class="table table-striped" *ngIf="tasks.length > 0">
  <tr>
    <th>Название</th>
    <th>Время создания</th>
    <th>Кто создал</th>
    <th>Статус</th>
    <th></th>
  </tr>
  <tr *ngFor="let task of tasks | paginate: { itemsPerPage: perPage, currentPage: pageNum }">
    <td>
      <a [routerLink]="['/mailing/tasks/show', task.id]">
        {{ task.name }}
      </a>
    </td>
    <td>
      {{ task.created_at | dateTimeFormat }}
    </td>
    <td>
      {{ task.created_by?.username }}
    </td>
    <td>
      {{ task.status | appMailingTaskStatus }}
    </td>
    <td style="min-width: 245px;">
      <button *ngIf="task.status === 'STOPPED'" class="btn btn-primary" (click)="startTask(task)">
        <span class="fa fa-play fa-lg" aria-hidden="true"></span>
        Запустить
      </button>
      <button *ngIf="task.status === 'STARTED'" class="btn btn-danger" (click)="stopTask(task)">
        <span class="fa fa-stop fa-lg" aria-hidden="true"></span>
        Остановить
      </button>
    </td>
  </tr>
</table>

<pagination-controls
  (pageChange)="pageNum = $event"
  previousLabel=""
  nextLabel=""
></pagination-controls>
