import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {getFirstDayOfMonth} from 'ngx-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {MailingTaskService} from '../mailing-task/services/mailing-task-service';
import {TaskReportCriteria} from '../mailing-task/models/mailing-task.model';
import {GlobalLoadingService} from '../shared/global-loading.service';


@Component({
  templateUrl: './report.component.html'
})
export class ReportComponent implements OnInit {
  public today = new Date();
  public firstMonthDay = getFirstDayOfMonth(this.today);
  public sourcesList: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private mailingTaskService: MailingTaskService,
    private globalLoading: GlobalLoadingService
  ) {}


  filterForm = new FormGroup({
    created_at__gt: new FormControl(this.firstMonthDay),
    created_at__lt: new FormControl(this.today),
    source: new FormControl()
  });

  ngOnInit() {
    this.sourcesList = this.route.snapshot.data['sources'];
    if (this.sourcesList.length) {
      this.filterForm.controls.source.setValue(this.sourcesList[0]);
    }
  }

  downloadReport() {
    const criteria: TaskReportCriteria = {
      created_at__lt: this.filterForm.controls.created_at__lt.value,
      created_at__gt: this.filterForm.controls.created_at__gt.value,
      source: this.filterForm.controls.source.value,
    };
    let oReport = this.mailingTaskService.getTaskReport(criteria);
    oReport = this.globalLoading.bind(oReport);
    oReport.subscribe(
      result => {
        const reportFile = new Blob([result.data], {type: 'text/csv'});
        const url = window.URL.createObjectURL(reportFile);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'report.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (err) => {
        alert(`Ошибка запроса: ${err.message}`);
      }
    );
  }

}
