/*!
 * Display mailing task
 */
import {Component, Input, OnInit} from '@angular/core';
import {MailingItem, MailingTask, TaskStatus} from './models/mailing-task.model';
import {MailingTaskService} from './services/mailing-task-service';
import {GlobalLoadingService} from '../shared/global-loading.service';


@Component({
  selector: 'app-display-mailing-task',
  templateUrl: './display-mailing-task.component.html'
})
export class DisplayMailingTaskComponent implements OnInit {
  @Input() task: MailingTask;
  @Input() check = false;

  showReportButtonTip = false;

  filteredItems: MailingItem[] = [];

  perPage = 50;
  pageNum = 1;

  constructor(private taskService: MailingTaskService, private globalLoading: GlobalLoadingService) {}

  ngOnInit(): void {
    this.filterItems('' );
  }

  filterItems(search: string) {
    if (search) {
      this.filteredItems = this.task.mailing_items.slice(0).filter(value => {
        return (value.recipient_selector.toLowerCase().indexOf(search) > -1) ||
          (value.email_target && value.email_target.address_to.toLowerCase().indexOf(search) > -1);
      });
    } else {
      this.filteredItems = this.task.mailing_items;
    }

  }

  get methods() {
    return [
      this.task.email_options ? 'email' : '',
      this.task.sms_options ? 'SMS' : ''
    ].filter((a) => a).join(', ');
  }

  itemHasError(item: MailingItem) {
    if (item.sms_target && item.sms_target.status === 'FAIL') {
      return true;
    }
    if (item.email_target && item.email_target.status === 'FAIL') {
      return true;
    }
    return item.personal_target && item.personal_target.status === 'FAIL';
  }

  startTask() {
    if (!window.confirm(`Действительно запустить задание "${this.task.name}"?`)) {
      return;
    }
    this.taskService.setTaskStatus(this.task.id, TaskStatus.Started).subscribe(
      () => {
        this.task.status = TaskStatus.Started;
      },
      (err) => {
        alert(`Ошибка запуска: ${err.message}`);
      }
    );
  }

  stopTask() {
    if (!window.confirm(`Действительно остановить задание "${this.task.name}"?`)) {
      return;
    }
    this.taskService.setTaskStatus(this.task.id, TaskStatus.Stopped).subscribe(
      () => {
        this.task.status = TaskStatus.Stopped;
      },
      (err) => {
        alert(`Ошибка остановки: ${err.message}`);
      }
    );
  }

  downloadReport() {
    // Download report
    let oReport = this.taskService.getTaskReport({id: this.task.id});
    oReport = this.globalLoading.bind(oReport);
    oReport.subscribe(
      result => {
        const reportFile = new Blob([result.data], {type: 'text/csv'});
        const url = window.URL.createObjectURL(reportFile);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = 'report.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      (err) => {
        alert(`Ошибка запроса: ${err.message}`);
      }
    );
  }


}
