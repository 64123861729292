// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stsAuthority: 'https://openid.rinet.ru',
  stsClientId: 'rinet-sender',
  baseDomain: 'rinet.ru',
};

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
