import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CreateMailingTaskComponent} from './mailing-task/create-mailing-task.component';
import {ListMailingTasksComponent} from './mailing-task/list-mailing-tasks.component';
import {CanDeactivateGuard} from './shared/can-deactivate-guard.service';
import {ShowMailingTaskComponent} from './mailing-task/show-mailing-task.component';
import {MailingTaskResolver} from './mailing-task/resolvers/mailing-task.resolver';
import {MailingTaskListResolver} from './mailing-task/resolvers/mailing-task-list.resolver';
import {DefaultComponent} from './default.component';
import {ToolsListComponent} from './tools/tools-list.component';
import {NewListToolsComponent} from './tools/new-list-tools.component';
import {ReportComponent} from './report/report.component';
import {ReportResolver} from './mailing-task/resolvers/report.resolver';
import {AuthGuardService} from './auth-guard.service';

const routes: Routes = [
  {path: '', canActivate: [AuthGuardService], children: [
    {path: 'mailing/tasks/show', resolve: {tasks: MailingTaskListResolver}, component: ListMailingTasksComponent},
    {path: 'mailing/tasks/show/:id', resolve: {task: MailingTaskResolver}, component: ShowMailingTaskComponent},
    {path: 'mailing/tasks/create', component: CreateMailingTaskComponent, canDeactivate: [CanDeactivateGuard]},
    {path: 'report', resolve: {sources: ReportResolver}, component: ReportComponent},
    {path: 'tools', component: ToolsListComponent},
    {path: 'tools/new_list', component: NewListToolsComponent},
    {path: '', component: DefaultComponent, pathMatch: 'full'},
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
