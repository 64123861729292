/**
 * Mailing task resolver
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {MailingTask} from '../models/mailing-task.model';
import {MailingTaskService} from '../services/mailing-task-service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MailingTaskResolver implements Resolve<Observable<MailingTask>> {
  constructor(private service: MailingTaskService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MailingTask> {
    return this.service.getTask(route.params['id'])
      .pipe(catchError((err) => {
        if (err.status === 404) {
          this.router.navigate(['/404']);
        }
        alert(err.message);
        return throwError(err);
      }));
  }

}
