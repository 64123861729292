import {Component, OnInit} from '@angular/core';
import {MailingTask, TaskStatus} from './models/mailing-task.model';
import {ActivatedRoute} from '@angular/router';
import {MailingTaskService} from './services/mailing-task-service';

@Component({
  templateUrl: './list-maling-tasks.component.html'
})
export class ListMailingTasksComponent implements OnInit {
  public tasks: MailingTask[];
  pageNum: number;
  perPage = 10;

  constructor(private route: ActivatedRoute, public taskService: MailingTaskService) {}

  ngOnInit(): void {
    this.tasks = this.route.snapshot.data['tasks'];
  }

  startTask(task: MailingTask) {
    if (!window.confirm(`Действительно запустить задание "${task.name}"?`)) {
      return;
    }
    this.taskService.setTaskStatus(task.id, TaskStatus.Started).subscribe(
      () => {
        task.status = TaskStatus.Started;
      },
      (err) => {
        alert(`Ошибка запуска: ${err.message}`);
      }
    );
  }

  stopTask(task: MailingTask) {
    if (!window.confirm(`Действительно остановить задание "${task.name}"?`)) {
      return;
    }
    this.taskService.setTaskStatus(task.id, TaskStatus.Stopped).subscribe(
      () => {
        task.status = TaskStatus.Stopped;
      },
      (err) => {
        alert(`Ошибка остановки: ${err.message}`);
      }
    );
  }

}
