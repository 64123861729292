import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import {CreateMailingTaskComponent} from './mailing-task/create-mailing-task.component';
import {NavComponent} from './nav.component';
import {ListMailingTasksComponent} from './mailing-task/list-mailing-tasks.component';
import {FileUploadModule} from 'ng2-file-upload';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {NgxEditorModule} from 'ngx-editor';
import {AccordionModule, BsDatepickerConfig, BsDatepickerModule, TabsModule} from 'ngx-bootstrap';
import {MailingTaskService} from './mailing-task/services/mailing-task-service';
import {ApiInterceptor} from './http-interceptors/api.interceptor';
import {CanDeactivateGuard} from './shared/can-deactivate-guard.service';
import {DisplayMailingTaskComponent} from './mailing-task/display-mailing-task.component';
import {ScrollToAfterViewDirective} from './shared/scroll-to-after-view.directive';
import {ShowMailingTaskComponent} from './mailing-task/show-mailing-task.component';
import {MailingTaskResolver} from './mailing-task/resolvers/mailing-task.resolver';
import {MailingTaskListResolver} from './mailing-task/resolvers/mailing-task-list.resolver';
import {SanitizeHtmlPipe} from './shared/sanitize-html.pipe';
import {MailingTargetStatusPipe} from './mailing-task/pipes/mailing-target-status.pipe';
import {MailingTaskStatusPipe} from './mailing-task/pipes/mailing-task-status.pipe';
import {DefaultComponent} from './default.component';
import {DateTimeFormatPipe} from './shared/date-time-format.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import {GlobalLoadingService} from './shared/global-loading.service';
import {Constants} from './shared/constants';
import {DateFormatPipe} from './shared/date-format.pipe';
import {ToolsListComponent} from './tools/tools-list.component';
import {NewListToolsComponent} from './tools/new-list-tools.component';
import {ReportComponent} from './report/report.component';
import {ReportResolver} from './mailing-task/resolvers/report.resolver';
import {AuthGuardService} from './auth-guard.service';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: Constants.BS_DATE_FMT
  });
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DefaultComponent,
    CreateMailingTaskComponent,
    ListMailingTasksComponent,
    ShowMailingTaskComponent,
    DisplayMailingTaskComponent,
    ScrollToAfterViewDirective,
    SanitizeHtmlPipe,
    DateTimeFormatPipe,
    DateFormatPipe,
    MailingTargetStatusPipe,
    MailingTaskStatusPipe,
    ToolsListComponent,
    NewListToolsComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxEditorModule,
    NgxPaginationModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/sender/api'],
        sendAccessToken: true
      }
    })
  ],
  providers: [
    GlobalLoadingService,
    MailingTaskService,
    MailingTaskResolver,
    MailingTaskListResolver,
    ReportResolver,
    CanDeactivateGuard,
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
