<form [formGroup]="filterForm">
  <div class="form-group row">
    <label class="col-sm-2 col-form-label" for="created_at__gt">Дата начала</label>
    <div class="col-sm-10">
      <input type="text" id="created_at__gt" class="form-control" formControlName="created_at__gt"
             placeholder="Дата начала" bsDatepicker placement="bottom" [maxDate]="today">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label" for="created_at__lt">Дата окончания</label>
    <div class="col-sm-10">
      <input type="text" id="created_at__lt" class="form-control" formControlName="created_at__lt"
             placeholder="Дата окончания" bsDatepicker placement="bottom" [maxDate]="today">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-2 col-form-label" for="source">Источник</label>
    <div class="col-sm-10">
      <select id="source" class="form-control" formControlName="source">
        <option *ngFor="let source of sourcesList" value="{{source}}">
          {{ source }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <button (click)="downloadReport()" class="btn btn-dark">Сформировать отчёт</button>
  </div>
</form>
