/*!
 * Mailing list model
 */
import {Recipient} from '../../shared/recipient.model';
import {IUser} from '../../shared/user.model';
import DateTimeFormat = Intl.DateTimeFormat;


// Task list method enum

export enum ListMethod {
  File = 'FILE',
  Filter = 'FILTER'
}

export enum TaskStatus {
  Started = 'STARTED',
  Stopped = 'STOPPED',
  Finished = 'FINISHED'
}


// Mailing target status
export enum MailingStatus {
  Initial = 'INITIAL',
  Processing = 'PROCESS',
  Retrying = 'RETRY',
  Failed = 'FAIL',
  Complete = 'COMPLETE'
}


// Mailing task class
export class MailingTask {
  id?: number;
  created_at?: Date;
  created_by?: IUser;
  name: string;
  list_method: ListMethod;
  list_method_file_cond?: IListMethodFileCond;
  list_method_filter_cond?: any;

  sms_options?: ISmsOptions;
  email_options?: IEmailOptions;
  personal_options?: IPersonalOptions;

  status: TaskStatus;

  mailing_items: MailingItem[];

}


// List method file conditions
export interface IListMethodFileCond {
  file_charset: string;
  file_content: string;
}


// Sms task options
export interface ISmsOptions {
  template: string;
  ignore_subscription_flag: boolean;
}


// Email task options
export interface IEmailOptions {
  subject_template: string;
  template: string;
  address_from: string;
  address_cc: string;
  address_bcc: string;
  address_to_main: boolean;
  address_to_add: boolean;
  address_to_buh: boolean;
}


// Personal page message task options
export interface IPersonalOptions {
  subject_template: string;
  template: string;
  popup: boolean;
  date_from: Date;
  date_to?: Date;
}


// Mailing item class
export class MailingItem {
  id: number;
  recipient_selector: string;
  recipient?: Recipient;
  recipient_data?: string;

  sms_target?: SmsTarget;
  email_target?: EmailTarget;
  personal_target?: PersonalTarget;

}


// Basic mailing target class
export class MailingTarget {
  status: MailingStatus;
  error_text: string;
}


// SMS target class
export class SmsTarget extends MailingTarget {
  login: string;
  text: string;
}


// Email target class
export class EmailTarget extends MailingTarget {
  address_to: string;
  address_from: string;
  address_reply_to: string;
  address_cc: string;
  address_bcc: string;
  subject: string;
  body: string;
  error_text: string;
  report_diagnostic_code: string;
}

// Personal message target class
export class PersonalTarget extends MailingTarget {
  subject: string;
  body: string;
  popup: boolean;
  uid: number;
  date_from: Date;
  date_to: Date;
}

// Task report criteria
export class TaskReportCriteria {
  id?: number;
  created_at__gt?: Date;
  created_at__lt?: Date;
  source?: string;
}
