<h3 *ngIf="check">Проверка задания "{{ task.name }}"</h3>
<h3 *ngIf="!check">Задание "{{ task.name }}"</h3>

<div class="row">
  <div class="col-sm-4">
    <div *ngIf="task.status === 'STOPPED' && !check" class="mb-4 mt-2">
      <button class="btn btn-primary" (click)="startTask()">
        <span class="fa fa-play fa-lg" aria-hidden="true"></span>
        Запустить
      </button>
    </div>
    <div *ngIf="task.status === 'STARTED' && !check" class="mb-4 mt-2">
      <button class="btn btn-danger" (click)="stopTask()">
        <span class="fa fa-stop fa-lg" aria-hidden="true"></span>
        Остановить
      </button>
    </div>
    <table class="table">
      <ng-container *ngIf="!check">
        <tr>
          <th>Статус</th>
          <td>
            {{ task.status | appMailingTaskStatus }}
          </td>
        </tr>
        <tr>
          <th>Создатель</th>
          <td>
            {{ task.created_by?.username }}
          </td>
        </tr>
        <tr>
          <th>Время создания</th>
          <td>
            <span *ngIf="task.created_at">
              {{ task.created_at | dateTimeFormat }}
            </span>
          </td>
        </tr>
      </ng-container>
      <tr>
        <th>Способ отправки</th>
        <td>
          {{ methods }}
        </td>
      </tr>
      <tr>
        <th>Количество получателей</th>
        <td>
          {{ task.mailing_items.length }}
        </td>
      </tr>
    </table>
  </div>
</div>

<label *ngIf="task.id">
  <button (click)="downloadReport()" class="btn btn-dark"
          (mouseenter)="showReportButtonTip = true"
          (mouseleave)="showReportButtonTip = false"
  >
    <span class="fa fa-file-excel-o fa-lg" aria-hidden="true"></span>
    Скачать отчёт
  </button>
  <small class="text-secondary" *ngIf="showReportButtonTip">
    Если кнопка не работает, попробуйте отключить AdBlock/uBlock
  </small>
</label>

<hr>
<div>
  <div class="mb-4">
    <input type="text" placeholder="Поиск" (input)="filterItems(searchInput.value.trim().toLowerCase())" #searchInput>
  </div>
  <pagination-controls
    (pageChange)="pageNum = $event"
    previousLabel=""
    nextLabel=""
  ></pagination-controls>
  <accordion>
    <accordion-group *ngFor="let item of filteredItems | paginate: { itemsPerPage: perPage, currentPage: pageNum }"
                     [heading]="item.recipient_selector + (item.hasError ? ' - Ошибка' : '')"
                     [panelClass]="(!check && itemHasError(item)) ? 'error' : ''"
    >

      <div *ngIf="item.sms_target" class="border-bottom pb-2">
        <h5>SMS</h5>
        <p>
          <ng-container *ngIf="!check">
            <small><strong>Статус</strong> {{ item.sms_target.status | appMailingTargetStatus }}</small>
            <br>
            <ng-container *ngIf="item.sms_target.status === 'FAIL'">
              <small><strong>Ошибка</strong> <pre class="text-danger">{{item.sms_target.error_text}}</pre></small>
            </ng-container>
          </ng-container>
          <small><strong>Логин</strong> {{ item.sms_target.login }}</small>
        </p>
        <h6>Текст: </h6>
        <pre class="highlight">{{ item.sms_target.text }}</pre>
      </div>

      <div *ngIf="item.email_target" class="border-bottom pb-2">
        <h5>E-mail</h5>
        <p>
          <ng-container *ngIf="!check">
            <small><strong>Статус</strong> {{ item.email_target.status | appMailingTargetStatus }}</small>
            <br>
            <ng-container *ngIf="item.email_target.status === 'FAIL'">
              <small>
                <strong>Ошибка</strong>
                <pre *ngIf="item.email_target.error_text" class="text-danger">{{item.email_target.error_text}}</pre>
                <pre *ngIf="item.email_target.report_diagnostic_code" class="text-danger">{{item.email_target.report_diagnostic_code}}</pre>
              </small>
            </ng-container>
          </ng-container>
          <small><strong>Адрес отправителя</strong> {{ item.email_target.address_from }}</small>
          <br>
          <ng-container *ngIf="item.email_target.address_reply_to">
            <small><strong>Адрес для ответа</strong> {{ item.email_target.address_reply_to }}</small>
            <br>
          </ng-container>
          <ng-container *ngIf="item.email_target.address_cc">
            <small><strong>Копия</strong> {{ item.email_target.address_cc }}</small>
            <br>
          </ng-container>
          <ng-container *ngIf="item.email_target.address_bcc">
            <small><strong>Скрытая копия</strong> {{ item.email_target.address_bcc }}</small>
            <br>
          </ng-container>
          <small><strong>Адрес получателя</strong> {{ item.email_target.address_to }}</small>
          <br>
          <small><strong>Тема</strong> {{ item.email_target.subject }}</small>
        </p>
        <h6>Текст: </h6>
        <div class="highlight" [innerHTML]="item.email_target.body | appSanitizeHtml"></div>
      </div>

      <div *ngIf="item.personal_target" class="border-bottom pb-2">
        <h5>Сообщение в ЛК</h5>
        <p>
          <ng-container *ngIf="!check">
            <small><strong>Статус</strong> {{ item.personal_target.status | appMailingTargetStatus }}</small>
            <br>
            <ng-container *ngIf="item.personal_target.status === 'FAIL'">
              <small><strong>Ошибка</strong> <pre class="text-danger">{{item.personal_target.error_text}}</pre></small>
            </ng-container>
          </ng-container>
          <small><strong>Дата начала</strong> {{ item.personal_target.date_from | dateFormat }}</small>
          <br>
          <small>
            <strong>Дата окончания </strong>
            <span *ngIf="item.personal_target.date">{{ item.personal_target.date_to | dateFormat }}</span>
            <span *ngIf="!item.personal_target.date">никогда</span>
          </small>
          <br>
          <small >
            <strong>Попап</strong> {{ item.personal_target.popup? "да" : "нет" }}
          </small>
          <br>
          <small>
            <strong>Тема</strong> {{ item.personal_target.subject }}
          </small>
          <br>
          <small>
            <strong>Request </strong>
            <a href="http://request.rinet.ru/browse.php?page=request&index={{item.personal_target.uid}}"
               target="__new_request__{{ item.personal_target.uid }}">
              {{ item.personal_target.uid }}
            </a>
          </small>
        </p>
        <h6>Текст (ссылки в тексте движок ЛК сделает ссылками): </h6>
        <div class="highlight">{{ item.personal_target.body }}</div>
      </div>

    </accordion-group>
  </accordion>
  <div class="mt-4">
    <pagination-controls
      (pageChange)="pageNum = $event"
      previousLabel=""
      nextLabel=""
    ></pagination-controls>
  </div>
</div>
