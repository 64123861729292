import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appScrollToAfterView]'
})
export class ScrollToAfterViewDirective implements AfterViewInit {
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.scrollIntoView();
  }
}
