import {Component, EventEmitter, Output} from '@angular/core';
import {AuthService} from './auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html'
})
export class NavComponent {

  @Output() emitLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(public authService: AuthService) { }

  login() {
    this.emitLogin.emit();
  }

  logout() {
    this.emitLogout.emit();
  }

}
