<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/">Sender</a>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/mailing/tasks/create">
          Создать задание
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/mailing/tasks/show">
          Задания
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/report">
          Отчёты
        </a>
      </li>
      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/tools">
          Утилиты
        </a>
      </li>
    </ul>

    <span class="navbar-text" *ngIf="authService.loggedIn">
      {{ authService.name }}
      <button class="btn btn-dark" (click)="logout()">Выйти</button>
    </span>
    <span class="navbar-nav" *ngIf="!authService.loggedIn">
      <button class="btn btn-dark" (click)="login()">Войти</button>
    </span>
  </nav>
</div>
