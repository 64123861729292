<h3><a routerLink="/tools">Утилиты</a> -></h3>
<h3>Создание нового списка на базе старого</h3>

<p class="mt-lg-5 mb-lg-5">
  Работает это очень просто: в качестве первого файла отдаётся исходный файл рассылки, в качестве второго - любой файл,
  у которого в первой колонке будет список логинов. Например, такой файл легко получить, сделав фильтр по неуспешной
  доставке в Excel или Google Drive на выгрузке из задания.
  <br>
  <br>
  Если при нажатии на кнопку "Сделать" ничего не происходит, проверьте, не включен ли AdBlock/uBlock.
</p>


<form [formGroup]="listForm">
  <strong>Исходный файл</strong>
  <div class="form-group row">
    <label for="listCsvCharset" class="col-sm-2 col-form-label">Кодировка исходного файла</label>
    <div class="col-sm-10">
      <select class="form-control" id="listCsvCharset" formControlName="listCsvCharset">
        <option value="CP-1251">CP-1251 (Windows, Excel)</option>
        <option value="UTF-8">UTF-8 (OpenOffice, Google Таблицы, LibreOffice, Linux)</option>
      </select>
    </div>
  </div>
  <div class="form-group row mb-lg-5">
    <label for="listFile" class="col-sm-2 col-form-label">Исходный файл CSV</label>
    <div class="col-sm-10">
      <input type="file" class="form-control" id="listFile" (change)="listFileChange($event)"/>
    </div>
  </div>

  <hr>
  <strong>Файл-фильтр</strong>
  <div class="form-group row">
    <label for="filterCsvCharset" class="col-sm-2 col-form-label">Кодировка файла-фильтра</label>
    <div class="col-sm-10">
      <select class="form-control" id="filterCsvCharset" formControlName="filterCsvCharset">
        <option value="CP-1251">CP-1251 (Windows, Excel)</option>
        <option value="UTF-8">UTF-8 (OpenOffice, Google Таблицы, LibreOffice, Linux)</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label for="filterFile" class="col-sm-2 col-form-label">Файл-фильтр CSV</label>
    <div class="col-sm-10">
      <input type="file" class="form-control" id="filterFile" (change)="filterFileChange($event)"/>
    </div>
  </div>

  <div class="form-group">
    <button (click)="makeNewList()" class="btn btn-success float-right" [disabled]="checkButtonDisable">
      Сделать
    </button>
    <i class="clearfix"></i>
  </div>
</form>
