import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


@Injectable()
export class GlobalLoadingService {
  private _counter = 0;
  private _names = new Set<string>();

  show() {
    this._counter++;
  }

  show_name(name: string) {
    if (!this._names.has(name)) {
      this._names.add(name);
    }
  }

  hide() {
    this._counter--;
  }

  hide_name(name: string) {
    if (this._names.has(name)) {
      this._names.delete(name);
    }
  }

  bind<T>(observable: Observable<T>): Observable<T> {
    this.show();
    return observable.pipe(
      tap(() => this.hide()),
      catchError((error: Response | any) => {
        this.hide();
        return throwError(error);
      })
    );
  }

  get visible(): boolean {
    return this._counter > 0 || this._names.size > 0;
  }

}
